import React, { useEffect } from "react"

const RedirectTemplate = () => {
  useEffect(() => {
    window.location.href = "/"
  }, [])

  return <>Redirecting...</>
}

RedirectTemplate.propTypes = {}

export default RedirectTemplate
